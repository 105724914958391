import { ReactNode } from "react";

import Card from "./Card";

interface HorizontalCardProps {
  image: ReactNode;
  title: string;
  text: string;
  className?: string;
}

const HorizontalCard = ({
  image,
  title,
  text,
  className,
}: HorizontalCardProps) => {
  return (
    <Card
      orientation="horizontal"
      className={`relative h-96 col-span-3 !justify-end ${className}`}
    >
      <div className="absolute left-0 top-0 w-1/2 flex basis-1/2 justify-center items-center">
        {image}
      </div>
      <div className="flex flex-col gap-2 px-4 basis-1/2">
        <Card.Title>{title}</Card.Title>
        <Card.Text>{text}</Card.Text>
      </div>
    </Card>
  );
};

export default HorizontalCard;
