import React from "react";

import { Ecosystem } from "../../assets-protocol-v4/animations/Ecosystem";
import { RedirectLinks } from "../../utils/common";
import SecondaryButton from "../SecondaryButton/index";

const EcosystemSuiteSection = () => {
  return (
    <section className="z-[1] flex flex-col lg:gap-1 gap-5 mx-6 lg:mx-sides lg:grid lg:grid-cols-3">
      <div className="flex flex-col lg:gap-14 lg:col-span-1 gap-5 w-full">
        <span
          data-aos="fade-down"
          data-aos-once="true"
          data-aos-delay="0"
          data-aos-duration="600"
          className="font-sans lg:text-[32px] text-[28px] leading-12 font-normal uppercase"
        >
          Explore Our Comprehensive Ecosystem Suite
        </span>
        <div
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-delay="100"
          data-aos-duration="600"
          className="flex flex-col gap-4 font-inter text-base font-normal pr-4"
        >
          <span className="text-protocolNew-para-text">
            Spheron’s integrated product suite benefits compute providers and
            users alike from seamless connectivity to advanced features, our
            ecosystem offers everything you need to succeed.
          </span>
          <span className="text-protocolNew-base">
            Join us and unlock the full potential of our innovative solutions.
          </span>
        </div>
        <div
          data-aos="zoom-in"
          data-aos-once="true"
          data-aos-duration="400"
          data-aos-delay="0"
        >
          <SecondaryButton
            className="uppercase w-max h-min py-4 px-8 lg:mt-2"
            variant="dark"
            onClick={() => {
              window.open(RedirectLinks.APP);
            }}
          >
            Demo our Dashboard
          </SecondaryButton>
        </div>
      </div>
      <div
        data-aos="zoom-in-up"
        data-aos-once="true"
        data-aos-delay="0"
        data-aos-duration="700"
        className="bg-protocolNew-base py-5 lg:p-4 lg:col-span-2 lg:pb-8 flex flex-col gap-4 w-full"
      >
        <div className="flex w-[70%] max-w-[250px] lg:mt-16 mx-auto justify-center h-auto relative">
          <Ecosystem />
        </div>
        <div className="relative flex flex-col mt-auto gap-2 text-center px-10 lg:px-0">
          <span className="font-jetbrains text-white text-[17px] font-normal leading-5 uppercase">
            <div> Unleash your full potential using Spheron’s</div>
            <div> interconnected offerings</div>
          </span>
        </div>
      </div>
    </section>
  );
};

export default EcosystemSuiteSection;
