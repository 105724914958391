import Image from "next/image";

import AdaptImage from "../../assets-protocol-v4/images/providers-section/adapt-to-demand.svg";
import ReachImage from "../../assets-protocol-v4/images/providers-section/expand-your-reach.webp";
import RewardsImage from "../../assets-protocol-v4/images/providers-section/extra-rewards.webp";
import MonetizeImage from "../../assets-protocol-v4/images/providers-section/monetize-unused-resources.webp";
import { HorizontalCard, VerticalCard } from "../Card";
import SecondaryButton from "../SecondaryButton";
import SectionHeading from "../SectionHeading";
import SectionSubtext from "../SectionSubtext";

const cardConfigs = [
  {
    id: 1,
    title: "MONETIZE UNUSED RESOURCES",
    text: "Turn idle capacity into income by offering your excess compute power",
    image: <Image src={MonetizeImage} alt="" />,
    type: "vertical",
  },
  {
    id: 1,
    title: "EXPAND YOUR REACH",
    text: "Increase visibility and opportunities by joining a global network of users",
    image: <Image src={ReachImage} alt="" />,
    type: "vertical",
  },
  {
    id: 1,
    title: "RECEIVE EXTRA REWARDS",
    text: "Earn additional rewards based on performance and resource usage",
    image: <Image src={RewardsImage} alt="" className="grayscale" />,
    type: "vertical",
  },
  {
    id: 1,
    title: "ADAPT TO DEMAND",
    text: "Adjust your pricing, performance levels, and resource allocation based on real-time demand to optimize your GPU utilization and profitability",
    image: <Image src={AdaptImage} alt="" />,
    type: "horizontal",
  },
] as const;

const ProvidersSection = () => {
  return (
    <section className="z-[1] w-full lg:px-sides text-sans flex flex-col gap-6 lg:gap-16">
      <div className="flex flex-col gap-3 text-center items-center">
        <SectionSubtext variant="light">FOR PROVIDERS</SectionSubtext>
        <div className="flex flex-col gap-2">
          <SectionHeading>HAVE EXTRA COMPUTE POWER TO SPARE?</SectionHeading>
          <div
            data-aos="fade-in"
            data-aos-once="true"
            data-aos-delay="600"
            data-aos-duration="400"
            className="font-inter text-base text-protocolNew-light-sub-text opacity-50"
          >
            Earn passive returns from your excess compute, whether you have a
            powerful server or a personal laptop
          </div>
        </div>
        <div
          data-aos="zoom-in"
          data-aos-once="true"
          data-aos-delay="600"
          data-aos-duration="400"
          className="mt-2 lg:mt-5 h-[52px] w-[276px]"
        >
          <SecondaryButton
            variant="dark"
            onClick={() => {
              window.open(
                "https://docs.spheron.network/providers/setup-provider",
                "_blank"
              );
            }}
          >
            REGISTER AS A PROVIDER
          </SecondaryButton>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-4 px-0 lg:px-2">
        {cardConfigs.map((cardConfig) => {
          if (cardConfig.type === "vertical")
            return (
              <VerticalCard
                className="h-[413px] lg:h-[413px] md:h-[840px] sm:h-[720px] relative justify-end"
                key={cardConfig.id}
                image={cardConfig.image}
                title={cardConfig.title}
                text={cardConfig.text}
              />
            );
          else
            return (
              <>
                <HorizontalCard
                  className="col-span-3 py-0 lg:flex hidden"
                  key={cardConfig.id}
                  image={cardConfig.image}
                  title={cardConfig.title}
                  text={cardConfig.text}
                />
                <VerticalCard
                  className="h-[413px] lg:h-[413px] md:h-[840px] sm:h-[720px] relative justify-end lg:hidden flex"
                  key={cardConfig.id}
                  image={cardConfig.image}
                  title={cardConfig.title}
                  text={cardConfig.text}
                />
              </>
            );
        })}
      </div>
    </section>
  );
};

export default ProvidersSection;
