import { useState } from "react";

import { NextPage } from "next";

import Meta from "../components/Meta";
import BusinessAndDeveloperSection from "../components-protocol-v4/BusinessAndDeveloperSection";
import ComputeMarketplaceSection from "../components-protocol-v4/ComputeMarketplaceSection";
import Dot from "../components-protocol-v4/Dot";
import EcosystemSuiteSection from "../components-protocol-v4/EcosystemSuiteSection";
import FizzNotifyModal from "../components-protocol-v4/FizzNotifyModal";
import Footer from "../components-protocol-v4/Footer";
import HeroSection from "../components-protocol-v4/HeroSection";
import JoinProvidersNetworkSection from "../components-protocol-v4/JoinProvidersNetworkSection";
import NavBar from "../components-protocol-v4/NavBar";
import { NavbarItems } from "../components-protocol-v4/NavBar/Navbar";
import Partners from "../components-protocol-v4/Partners";
import ProviderSection from "../components-protocol-v4/ProvidersSection";
import { showFizzNotifyModal } from "../utils/common";
import { mainMeta } from "../utils/meta-data";

const HomeV4: NextPage = () => {
  const [showFizzNodeNotifyModal, setShowFizzNodeNotifyModal] = useState(() =>
    showFizzNotifyModal()
  );
  return (
    <main className="p-2 lg:p-5 bg-[#F0F0F3]">
      <Meta
        title={mainMeta.title}
        desc={mainMeta.desc}
        keyword={mainMeta.keyword}
        icon={mainMeta.icon}
        metaImage={mainMeta.metaImage}
        url={mainMeta.url}
      />
      <div className="isolate bg-white rounded-md pb-20 lg:pb-0">
        <div className="mt-[32px] sticky top-[89.5%] lg:top-0 bottom-0 -mb-[107px] z-[50] w-content mx-auto flex items-center justify-center">
          <NavBar
            setShowFizzNodeNotifyModal={setShowFizzNodeNotifyModal}
            page={NavbarItems.HOME}
          />
        </div>

        <HeroSection />
        <Partners />

        <div className="relative w-full h-full flex flex-col gap-y-44 pb-24 lg:pb-20 lg:!mt-0">
          <div className="absolute lg:mx-sides hidden lg:flex justify-center border-x lg:w-content h-full">
            <Dot className="hidden lg:flex top-0 left-0 -ml-1 -translate-y-1" />
            <Dot className="hidden lg:flex top-0 right-0 -mr-1 -translate-y-1" />
            <Dot className="hidden lg:flex bottom-0 left-0 -ml-1 translate-y-1" />
            <Dot className="hidden lg:flex bottom-0 right-0 -mr-1 translate-y-1" />
            <div className="w-1/3 border-x relative">
              <Dot className="hidden lg:flex top-0 left-0 -ml-1 -translate-y-1" />
              <Dot className="hidden lg:flex top-0 right-0 -mr-1 -translate-y-1" />
              <Dot className="hidden lg:flex bottom-0 left-0 -ml-1 translate-y-1" />
              <Dot className="hidden lg:flex bottom-0 right-0 -mr-1 translate-y-1" />
            </div>
          </div>
          <div className="absolute mx-6 lg:hidden flex justify-center border-x w-content-mobile h-full">
            <div className="w-1/3 border-x" />
          </div>
          <ComputeMarketplaceSection />
          <BusinessAndDeveloperSection />
          <EcosystemSuiteSection />
          <ProviderSection />
          <JoinProvidersNetworkSection />
        </div>

        <Footer />
        {showFizzNodeNotifyModal && (
          <FizzNotifyModal close={() => setShowFizzNodeNotifyModal(false)} />
        )}
      </div>
    </main>
  );
};

export default HomeV4;
