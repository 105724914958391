import Image from "next/image";

import DecentralizedImage from "../../assets-protocol-v4/images/hero-section/decentralized.svg";
import SpheronLogo from "../../assets-protocol-v4/spheron-logo.svg";
import Dot from "../Dot";
import PrimaryButton from "../PrimaryButton/PrimaryButton";

const HeroSection = () => {
  return (
    <section className="relative border-b border-protocolNew-border rounded-t-md">
      <div className="absolute lg:mx-sides hidden lg:flex justify-center border-x lg:w-content h-full z-[-1]">
        <div className="w-1/3 border-x"></div>
      </div>
      <div className="absolute mx-6 lg:hidden flex justify-center border-x w-content-mobile h-full z-[-1]">
        <div className="w-1/3 border-x"></div>
      </div>
      <div className="w-[calc(100%)] border-b bg-white rounded-t-md">
        <div className="h-[68px] w-full hidden lg:flex justify-between">
          <div className="relative w-sides flex justify-center items-center shrink-0 grow-0">
            <div className="cursor-pointer w-[100px] h-[34px]">
              <Image src={SpheronLogo} alt="spheron" />
            </div>
            <Dot className="bottom-0 right-0 translate-x-[4.5px] translate-y-[4.5px] z-50" />
          </div>
          <div className="w-content shrink-0 grow-0 border-x flex justify-center h-full">
            <div className="w-1/3 grow-0 shrink-0 border-x"></div>
          </div>
          <div className="w-sides grow-0 relative">
            <PrimaryButton
              variant="light"
              className="rounded-tr-md"
              onClick={() => {
                window.open(
                  "https://docs.spheron.network/user-guide/deploy-your-app",
                  "_blank"
                );
              }}
            >
              Deploy Now
            </PrimaryButton>
            <Dot className="bottom-0 left-0 -translate-x-[4.5px] translate-y-[4.5px] z-50" />
          </div>
        </div>
      </div>
      <div className="h-[68px] border-b bg-white py-2 justify-center items-center relative lg:hidden flex">
        <div className="absolute left-6 h-full border-x border-protocolNew-border w-[calc((100%_-_45px)_/_3)]"></div>
        <div className="absolute right-6 h-full border-x border-protocolNew-border w-[calc((100%_-_45px)_/_3)]"></div>
        <div className="cursor-pointer w-[calc((100%_-_90px)_/_3)] h-full flex items-center">
          <Image src={SpheronLogo} alt="spheron" className="" />
        </div>
        <Dot className="bottom-0 right-6 translate-x-[4.5px] translate-y-[4.5px]" />
        <Dot className="bottom-0 left-6 -translate-x-[3.5px] translate-y-[4.5px]" />
      </div>
      <div className="[animation-delay:1000ms] animate-landing-blur opacity-0 absolute left-6 right-6 lg:left-sides lg:right-sides w-content-mobile lg:w-content lg:pt-36 pt-32">
        <div className="min-w-full text-center">
          <Image
            src={DecentralizedImage}
            alt=""
            className="min-w-full w-full h-auto"
          />
        </div>
      </div>
      <h1 className="[animation-delay:1000ms] animate-landing-blur opacity-0 px-6 lg:px-sides text-center font-heading text-[17.5vw] lg:text-[12.8vw] leading-[84%] lg:leading-[83.4%] max-w-full pt-36 lg:pt-48">
        <div>GPU</div> <div>NETWORK</div>
      </h1>
      <h2 className="[animation-delay:1300ms] opacity-0 animate-landing-blur px-6 lg:px-sides text-sm lg:text-2xl leading-4 lg:leading-10 mb-[100px] mt-11 tracking-[1.4px]">
        <div className="text-center lg:text-left lg:ml-[33.4%]">
          DEVELOPERS, UNLEASH YOUR POTENTIAL.
        </div>
        <div className="text-center lg:text-left lg:ml-[33.4%]">
          BUSINESSES, OPTIMIZE YOUR GPU COST.
        </div>
      </h2>
      <div className="h-[88px] lg:h-11 border-t w-full">
        <div className="relative lg:mx-sides w-content-mobile lg:w-content h-full flex lg:flex-row flex-col">
          <div className="mx-6 lg:mx-0 h-full w-full">
            <PrimaryButton
              className="w-1/3 border-x lg:border-r-0"
              variant="light"
              onClick={() => {
                window.open(
                  "https://docs.spheron.network/user-guide/deploy-your-app",
                  "_blank"
                );
              }}
            >
              Rent GPU Now
            </PrimaryButton>
          </div>
          <div className="lg:w-1/3 shrink-0 border-b lg:border-b-0 w-[calc(100vw-20px)]"></div>
          <div className="mx-6 lg:mx-0 h-full w-full">
            <PrimaryButton
              className="w-1/3 border-x lg:border-l-0"
              variant="light"
              onClick={() => {
                window.open("https://docs.spheron.network/providers", "_blank");
              }}
            >
              Turn your idle GPU into profit
            </PrimaryButton>
          </div>
          <Dot className="top-0 left-0 -translate-x-[3.5px] -translate-y-1 ml-6 lg:ml-0" />
          <Dot className="bottom-0 left-0 -translate-x-[3.5px] translate-y-1 ml-6 lg:ml-0" />
          <Dot className="lg:hidden top-1/2 left-0 -translate-x-[3.5px] -translate-y-[3px] ml-6 lg:ml-0" />
          <Dot className="lg:hidden translate-x-[3.5px] lg:translate-x-[9.5px] top-1/2 -right-6 lg:right-0 -translate-y-[3px]" />
          <Dot className="translate-x-[3.5px] lg:translate-x-[3.5px] top-0 -right-6 lg:right-0 -translate-y-1" />
          <Dot className="translate-x-[3.5px] lg:translate-x-[3.5px] bottom-0 -right-6 lg:right-0 translate-y-1" />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
